import * as firebase from "firebase";
import useSWR from "swr";
// import Layout from "../components/layout";
import { fetchWithToken } from "../lib/fetch";
import { useAuth } from "../lib/auth";
import { CurrentConference } from "../type/current-conference";
import { LiveSession } from "../type/live-session";
import Comments from "../page-components/comments";

// const fetcher = (url, token) => fetch(url).then((r) => r.json());
const token =
  "eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiMzQyMzcwZjYtYzZlOC00OGUxLThhMzMtOWFkZThiYzE1ODM2IiwiZXhwIjoxNTkxMTU5MTg2fQ.UG2AK5cuzwDYqLXrYpvhh3CDr8NS1UquMwRTNQP4LsE";

export default function Home() {

  // const { data: currentConference } = useSWR<CurrentConference>(
  //   ["/api/v3/current_conference", token],
  //   (url, token) => fetchWithToken(url, token),
  //   { refreshInterval: 0 }
  // );
  return <div>Hello</div>;
  // const { data: liveSession, error } = useSWR<LiveSession>(
  //   () => [`/api/v3/sessions/${currentConference.liveSession.id}`, token],
  //   (url, token) => fetchWithToken(url, token)
  // );
  // if (!currentConference) return <div>loading...</div>;
  // if (error) return <div>failed to load</div>;
  // if (!currentConference.liveSession) return <div>準備中です</div>;
  // if (!liveSession) return <div>loading...</div>;
  // return (
  //   <Layout
  //     conferenceName={currentConference.name}
  //     currentSessionName={currentConference.liveSession.name}
  //   >
  //     <main className="container mx-auto">
  //       <Comments comments={liveSession.comments} />
  //     </main>
  //   </Layout>
  // );
}
